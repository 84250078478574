import React from 'react';
import { useTranslation } from 'react-i18next';

const Blog: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="blog-page">
      <div className="page-header">
        <h1>{t('pages.blog.title')}</h1>
        <p>{t('pages.blog.description')}</p>
      </div>
      {/* 博客内容 */}
    </div>
  );
};

export default Blog; 