import React from 'react';
import { useTranslation } from 'react-i18next';
import { SeoContentData, FAQ, Testimonial, Feature, UseCase } from '../types/SeoContent';
import '../styles/SeoContent.css';

const SeoContent: React.FC = () => {
  const { t } = useTranslation();

  // 使用类型安全的翻译获取
  const getFaqItems = (): FAQ[] => {
    const items = t('seoContent.faq.items', { returnObjects: true }) as FAQ[];
    return items || [];
  };

  const getTestimonialItems = (): Testimonial[] => {
    const items = t('seoContent.testimonials.items', { returnObjects: true }) as Testimonial[];
    return items || [];
  };

  const getFeatureItems = (): Feature[] => {
    const items = t('seoContent.features.items', { returnObjects: true }) as Feature[];
    return items || [];
  };

  const getUseCaseItems = (): UseCase[] => {
    const items = t('seoContent.useCases.items', { returnObjects: true }) as UseCase[];
    return items || [];
  };

  return (
    <div className="seo-content">
      {/* FAQ 部分 */}
      <section className="faq-section">
        <h2>{t('seoContent.faq.title')}</h2>
        <div className="faq-list">
          {getFaqItems().map((faq, index) => (
            <div key={index} className="faq-item">
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </section>

      {/* 用户评价部分 */}
      <section className="testimonials-section">
        <h2>{t('seoContent.testimonials.title')}</h2>
        <div className="testimonials-grid">
          {getTestimonialItems().map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <div className="testimonial-content">
                <p className="testimonial-text">"{testimonial.content}"</p>
                <div className="testimonial-author">
                  <div className="author-avatar">
                    {testimonial.author.charAt(0)}
                  </div>
                  <div className="author-info">
                    <h4>{testimonial.author}</h4>
                    <p>{testimonial.role}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* 功能特点部分 */}
      <section className="features-section">
        <h2>{t('seoContent.features.title')}</h2>
        <div className="features-grid">
          {getFeatureItems().map((feature, index) => (
            <div key={index} className="feature-card">
              <div className="feature-icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* 使用场景部分 */}
      <section className="use-cases-section">
        <h2>{t('seoContent.useCases.title')}</h2>
        <div className="use-cases-grid">
          {getUseCaseItems().map((useCase, index) => (
            <div key={index} className="use-case-card">
              <h3>{useCase.title}</h3>
              <p>{useCase.description}</p>
              <ul>
                {useCase.examples.map((example, i) => (
                  <li key={i}>{example}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default SeoContent; 