import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { templates } from '../config/templates';
import '../styles/TemplateSelector.css';

interface Props {
  selectedTemplate: string;
  onSelect: (templateId: string) => void;
}

const TemplateSelector: React.FC<Props> = ({ selectedTemplate, onSelect }) => {
  const { t } = useTranslation();
  const [previewTemplate, setPreviewTemplate] = useState<string | null>(null);

  const handleMouseEnter = (templateId: string) => {
    setPreviewTemplate(templateId);
  };

  const handleMouseLeave = () => {
    setPreviewTemplate(null);
  };

  const handleKeyPress = (e: React.KeyboardEvent, templateId: string) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onSelect(templateId);
    }
  };

  return (
    <div className="template-selector">
      <h2>{t('templates.title') as string}</h2>
      <p className="template-description">{t('templates.description') as string}</p>
      
      <div className="template-grid">
        {templates.map((template) => (
          <div
            key={template.id}
            className={`template-item ${selectedTemplate === template.id ? 'selected' : ''} ${
              previewTemplate === template.id ? 'preview' : ''
            }`}
            onClick={() => onSelect(template.id)}
            onMouseEnter={() => handleMouseEnter(template.id)}
            onMouseLeave={handleMouseLeave}
            onKeyPress={(e) => handleKeyPress(e, template.id)}
            tabIndex={0}
            role="button"
            aria-pressed={selectedTemplate === template.id}
          >
            <div className="template-preview">
              <img 
                src={template.previewImage} 
                alt={t(`templates.${template.id}.name`) as string}
                loading="lazy"
              />
              <div className="template-hover-info">
                <h4>{t(`templates.${template.id}.features`) as string}</h4>
                <ul>
                  {(t(`templates.${template.id}.featureList`, { returnObjects: true }) as string[]).map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
            
            <div className="template-info">
              <h3>{t(`templates.${template.id}.name`) as string}</h3>
              <p>{t(`templates.${template.id}.description`) as string}</p>
            </div>
            
            {selectedTemplate === template.id && (
              <div className="template-badge">
                {t('templates.selected') as string}
              </div>
            )}
            
            <div className="template-actions">
              <button 
                className="preview-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleMouseEnter(template.id);
                }}
              >
                <span className="button-icon">👁️</span>
                <span className="button-text">{t('templates.preview') as string}</span>
              </button>
              
              <button 
                className={`select-button ${selectedTemplate === template.id ? 'selected' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect(template.id);
                }}
              >
                <span className="button-text">
                  {selectedTemplate === template.id 
                    ? t('templates.selected') as string
                    : t('templates.select') as string}
                </span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplateSelector;