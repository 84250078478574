import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { RankingList } from '../types/RankingList';
import { templates } from '../config/templates';
import RankingImageGenerator from '../components/RankingImageGenerator';
import LoadingSpinner from '../components/LoadingSpinner';
import '../styles/pages/SharePage.css';

const SharePage: React.FC = () => {
  const location = useLocation();
  const slug = location.pathname;
  const [ranking, setRanking] = useState<RankingList | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    
    const loadRanking = async () => {
      if (!slug) {
        setError(t('share.invalidLink'));
        setLoading(false);
        return;
      }

      try {
        // 从 URL slug 中提取 shareId
        const shareId = slug.split('-').pop() || '';
        const workerUrl = process.env.REACT_APP_CF_WORKER_URL;
        
        if (!workerUrl) {
          throw new Error('Worker URL not configured');
        }

        // 通过 Worker 获取数据
        const response = await fetch(`${workerUrl}/kv/${encodeURIComponent(`ranking:${shareId}`)}`);
        
        if (!response.ok) {
          if (response.status === 404) {
            setError(t('share.notFound'));
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return;
        }

        const data = await response.json();
        if (data) {
          setRanking(data);
        } else {
          setError(t('share.notFound'));
        }
      } catch (err) {
        console.error('Failed to load ranking:', err);
        setError(t('share.loadError'));
      } finally {
        setLoading(false);
      }
    };

    loadRanking();
  }, [slug, t]);

  // 生成结构化数据
  const generateStructuredData = (ranking: RankingList) => {
    return {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      'name': ranking.title,
      'description': ranking.description,
      'numberOfItems': ranking.items.length,
      'itemListOrder': 'Ascending',
      'itemListElement': ranking.items.map((item, index) => ({
        '@type': 'ListItem',
        'position': item.rank,
        'item': {
          '@type': 'Thing',
          'name': item.title,
          'description': item.description,
          'image': item.imageUrl
        }
      }))
    };
  };

  // 生成 Meta 描述
  const generateMetaDescription = (ranking: RankingList) => {
    const topItems = ranking.items.slice(0, 3)
      .map(item => item.title)
      .join('、');
    return `${ranking.title} - ${ranking.description}。前三名：${topItems}。由 RankMaker.AI 生成的专业排行榜。`;
  };

  if (loading) {
    return (
      <div className="share-page loading">
        <LoadingSpinner />
        <p>{t('share.loading')}</p>
      </div>
    );
  }

  if (error || !ranking) {
    return (
      <div className="share-page error">
        <Helmet>
          <title>{t('share.error')} | RankMaker.AI</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <h1>{t('share.error')}</h1>
        <p>{error || t('share.unknownError')}</p>
        <Link to="/" className="back-home">{t('share.backHome')}</Link>
      </div>
    );
  }

  const template = templates.find(t => t.id === ranking.template);
  if (!template) {
    return (
      <div className="share-page error">
        <Helmet>
          <title>{t('share.error')} | RankMaker.AI</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <h1>{t('share.error')}</h1>
        <p>{t('share.templateNotFound')}</p>
        <Link to="/" className="back-home">{t('share.backHome')}</Link>
      </div>
    );
  }

  const metaDescription = generateMetaDescription(ranking);
  const structuredData = generateStructuredData(ranking);
  const canonicalUrl = `${window.location.origin}/share/${slug}`;

  return (
    <div className="share-page">
      <Helmet>
        <title>{`${ranking.title} | RankMaker.AI`}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={ranking.title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonicalUrl} />
        {ranking.items[0]?.imageUrl && (
          <meta property="og:image" content={ranking.items[0].imageUrl} />
        )}
        <link rel="canonical" href={canonicalUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={ranking.title} />
        <meta name="twitter:description" content={metaDescription} />
        {ranking.items[0]?.imageUrl && (
          <meta name="twitter:image" content={ranking.items[0].imageUrl} />
        )}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="share-header">
        <h1>{ranking.title}</h1>
        <p className="share-meta">
          {t('share.createdAt', { 
            date: new Date(ranking.createdAt).toLocaleDateString() 
          })}
        </p>
        <p className="share-description">{ranking.description}</p>
      </div>

      <div className="share-content">
        <RankingImageGenerator
          ranking={ranking}
          template={template}
        />
      </div>

      <div className="share-footer">
        <p>{t('share.poweredBy')}</p>
        <Link to="/" className="create-own">{t('share.createOwn')}</Link>
      </div>
    </div>
  );
};

export default SharePage; 