import React from 'react';
import { useTranslation } from 'react-i18next';
import RankingGenerator from '../services/RankingGenerator';
import RankingImageGenerator from '../components/RankingImageGenerator';
import TemplateSelector from '../components/TemplateSelector';
import LoadingSpinner from '../components/LoadingSpinner';
import SeoContent from '../components/SeoContent';
import { RankingList } from '../types/RankingList';
import { templates } from '../config/templates';
import '../styles/SearchInput.css';

interface RankingSettings {
  topN: number;
  imageCount: number;
  customImageCount: boolean;
}

const Home: React.FC = () => {
  const { t } = useTranslation();
  const [ranking, setRanking] = React.useState<RankingList | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState('modern');
  const [prompt, setPrompt] = React.useState('');
  const [settings, setSettings] = React.useState<RankingSettings>({
    topN: 10,
    imageCount: 3,
    customImageCount: false
  });
  
  const generator = new RankingGenerator();

  const handleGenerate = async () => {
    if (!prompt.trim()) return;
    
    setLoading(true);
    try {
      const result = await generator.generateRanking(prompt, settings);
      result.template = selectedTemplate;
      setRanking(result);
    } catch (error) {
      console.error('Failed to generate ranking:', error);
      alert(t('errorMessage'));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleGenerate();
    }
  };

  const handleSettingChange = (key: keyof RankingSettings, value: number | boolean) => {
    if (key === 'topN') {
      value = Math.max(1, Math.min(100, value as number));
    }
    if (key === 'imageCount' && typeof value === 'number') {
      value = Math.max(0, Math.min(100, value));
    }
    setSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const validateTopN = (value: number): boolean => {
    return value >= 1 && value <= 100;
  };

  const validateImageCount = (value: number): boolean => {
    return value >= 0 && value <= 100;
  };

  return (
    <div className="home-page">
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title gradient-text">{t('title')}</h1>
          <p className="hero-description">{t('description')}</p>
          
          <div className="search-container">
            <div className="search-input-wrapper">
              <input 
                type="text" 
                className="search-input"
                value={prompt}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder={t('inputPlaceholder')}
                disabled={loading}
              />
              <button 
                className="search-button"
                onClick={handleGenerate}
                disabled={loading || !prompt.trim()}
              >
                {loading ? (
                  <>
                    <span className="icon">🔄</span>
                    {t('loadingText')}
                  </>
                ) : (
                  <>
                    <span className="icon">✨</span>
                    {t('generateButton')}
                  </>
                )}
              </button>
            </div>

            <div className="search-options">
              <div className="search-option custom">
                <label>{t('settings.topN')}</label>
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={settings.topN}
                  onChange={(e) => handleSettingChange('topN', parseInt(e.target.value) || 1)}
                  disabled={loading}
                />
                {!validateTopN(settings.topN) && (
                  <div className="error-tip">{t('settings.topNError')}</div>
                )}
              </div>
              
              <div className="search-option image-count">
                <label>{t('settings.imageCount')}</label>
                <div className="image-count-inputs">
                  {settings.customImageCount ? (
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={settings.imageCount}
                      onChange={(e) => handleSettingChange('imageCount', parseInt(e.target.value) || 0)}
                      disabled={loading}
                    />
                  ) : (
                    <select 
                      value={settings.imageCount}
                      onChange={(e) => handleSettingChange('imageCount', Number(e.target.value))}
                      disabled={loading}
                    >
                      {[0, 1, 3, 5, 10].map(n => (
                        <option key={n} value={n}>
                          {t(`settings.imageCountOption_${n === 10 ? '10' : n === 0 ? '0' : n === 1 ? '1' : 'n'}`, { count: n })}
                        </option>
                      ))}
                    </select>
                  )}
                  <button
                    className="toggle-input-type"
                    onClick={() => handleSettingChange('customImageCount', !settings.customImageCount)}
                    disabled={loading}
                  >
                    {settings.customImageCount ? t('settings.presetInput') : t('settings.customInput')}
                  </button>
                </div>
                {settings.customImageCount && !validateImageCount(settings.imageCount) && (
                  <div className="error-tip">{t('settings.imageCountError')}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {!loading && (
        <section className="page-section template-section animate-fadeInUp">
          <TemplateSelector 
            selectedTemplate={selectedTemplate}
            onSelect={setSelectedTemplate}
          />
        </section>
      )}
      
      {loading && (
        <section className="page-section loading-section">
          <LoadingSpinner />
        </section>
      )}
      
      {ranking && templates.find(t => t.id === selectedTemplate) && (
        <section className="page-section result-section animate-fadeInUp">
          <RankingImageGenerator 
            ranking={ranking}
            template={templates.find(t => t.id === selectedTemplate)!}
          />
        </section>
      )}

      <section className="page-section seo-section animate-fadeInUp">
        <SeoContent />
      </section>
    </div>
  );
};

export default Home;