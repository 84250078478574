import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import { RankingList } from '../types/RankingList';
import { Template } from '../types/Template';
import RankingStorageService from '../services/RankingStorageService';
import SocialShare from './SocialShare';
import '../styles/RankingImage.css';

interface Props {
  ranking: RankingList;
  template: Template;
}

const RankingImageGenerator: React.FC<Props> = ({ ranking, template }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [generatedImage, setGeneratedImage] = useState<string | null>(null);
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<{[key: number]: number}>({});
  const [isExporting, setIsExporting] = useState(false);
  
  const storageService = new RankingStorageService();

  const generateImage = async () => {
    if (!containerRef.current) return;

    try {
      const canvas = await html2canvas(containerRef.current);
      const image = canvas.toDataURL('image/png');
      setGeneratedImage(image);
      
      const link = document.createElement('a');
      link.download = `${ranking.title}-ranking.png`;
      link.href = image;
      link.click();
    } catch (error) {
      console.error('Failed to generate image:', error);
    }
  };

  const exportAsPage = async () => {
    setIsExporting(true);
    try {
      ranking.template = template.name
      const shareUrl = await storageService.saveRanking(ranking);
      setShareUrl(shareUrl);
      
      // 复制链接到剪贴板
      await navigator.clipboard.writeText(shareUrl);
      alert(t('share.copySuccess'));
    } catch (error) {
      console.error('Failed to export ranking:', error);
      alert(t('share.copyFail'));
    } finally {
      setIsExporting(false);
    }
  };

  const handleImageSelect = (itemIndex: number, imageIndex: number) => {
    setSelectedImageIndex(prev => ({
      ...prev,
      [itemIndex]: imageIndex
    }));
  };

  const { style } = template;

  return (
    <div>
      <div 
        ref={containerRef} 
        className="ranking-container"
        style={{
          backgroundColor: style.backgroundColor,
          fontFamily: style.fontFamily,
          background: style.gradient || style.backgroundColor,
          borderRadius: style.borderRadius,
          boxShadow: style.boxShadow,
          padding: '24px'
        }}
      >
        <div className="ranking-header" style={{ 
          backgroundColor: style.headerBgColor,
          padding: '20px',
          borderRadius: style.borderRadius,
          marginBottom: '24px'
        }}>
          <h1 style={{ 
            color: style.titleColor,
            fontSize: '28px',
            marginBottom: '12px'
          }}>{ranking.title}</h1>
          <p style={{ 
            color: style.descriptionColor,
            fontSize: '14px'
          }}>{ranking.description}</p>
        </div>
        
        <div className="ranking-items" style={{
          display: 'flex',
          flexDirection: 'column',
          gap: style.itemSpacing
        }}>
          {ranking.items.map((item, itemIndex) => (
            <div 
              key={item.rank} 
              className="ranking-item"
              style={{ 
                backgroundColor: style.itemBackgroundColor,
                padding: style.itemPadding,
                borderRadius: style.borderRadius,
                border: style.itemBorder
              }}
            >
              <div className="ranking-item-header">
                <div className="rank" style={{ 
                  color: style.rankColor,
                  backgroundColor: style.rankBgColor,
                  fontSize: style.rankSize,
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  flexShrink: 0
                }}>
                  {item.rank}
                </div>
                <div className="content">
                  <h3 style={{ 
                    color: style.titleColor,
                    fontSize: style.titleFontSize,
                    marginBottom: '6px'
                  }}>{item.title}</h3>
                  <p style={{ 
                    color: style.descriptionColor,
                    fontSize: style.descriptionFontSize
                  }}>{item.description}</p>
                </div>

                {item.images && item.images.length > 0 && (
                  <div className="item-image">
                    <img 
                      src={item.images[selectedImageIndex[itemIndex] || 0].url}
                      alt={item.title}
                      style={{
                        width: '120px',
                        height: '120px',
                        objectFit: 'cover',
                        borderRadius: style.imageBorderRadius,
                        boxShadow: style.imageBoxShadow
                      }}
                    />
                  </div>
                )}
              </div>

              {item.images && item.images.length > 1 && (
                <div className="image-thumbnails">
                  {item.images.map((image, imageIndex) => (
                    <div 
                      key={imageIndex}
                      className={`thumbnail ${selectedImageIndex[itemIndex] === imageIndex ? 'active' : ''}`}
                      onClick={() => handleImageSelect(itemIndex, imageIndex)}
                    >
                      <img 
                        src={image.url} 
                        alt={`${item.title} - 缩略图 ${imageIndex + 1}`}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      
      <div className="actions">
        <button 
          onClick={generateImage} 
          className="action-button"
          disabled={isExporting}
        >
          {t('generator.buttons.download')}
        </button>
        
        <button 
          onClick={exportAsPage} 
          className="action-button share-button"
          disabled={isExporting}
        >
          {isExporting ? t('generator.status.generating') : t('generator.buttons.share')}
        </button>
      </div>

      {shareUrl && (
        <div className="share-info">
          <p>{t('share.copySuccess')}</p>
          <div className="share-url">
            <input type="text" value={shareUrl} readOnly />
            <button onClick={() => navigator.clipboard.writeText(shareUrl)}>
              {t('generator.buttons.copy')}
            </button>
          </div>
        </div>
      )}

      {generatedImage && (
        <SocialShare 
          imageUrl={generatedImage}
          title={`${ranking.title} - ${t('generator.title')}`}
          shareUrl={shareUrl}
        />
      )}
    </div>
  );
};

export default RankingImageGenerator;