interface OpenRouterResponse {
  choices: Array<{
    message: {
      content: string;
    };
  }>;
}

class OpenRouterClient {
  private apiKey: string;
  private baseURL: string;
  private siteUrl: string;
  private siteName: string;

  constructor() {
    this.apiKey = process.env.REACT_APP_OPENROUTER_API_KEY || '';
    this.baseURL = 'https://openrouter.ai/api/v1';
    this.siteUrl = process.env.REACT_APP_SITE_URL || window.location.origin;
    this.siteName = 'RankMaker.AI';
  }

  async createChatCompletion(messages: Array<{ role: string; content: string }>) {
    try {
      const response = await fetch(`${this.baseURL}/chat/completions`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.apiKey}`,
          'HTTP-Referer': this.siteUrl,
          'X-Title': this.siteName,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: 'openai/gpt-3.5-turbo',
          messages,
          temperature: 0.7,
          max_tokens: 2000,
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data: OpenRouterResponse = await response.json();
      return data;
    } catch (error) {
      console.error('OpenRouter API error:', error);
      throw error;
    }
  }

  // 生成榜单内容
  async generateRankingContent(prompt: string, topN: number): Promise<string> {
    const messages = [
      {
        role: "system",
        content: `你是一个专业的榜单生成助手。请根据用户的主题生成一个包含${topN}个项目的排行榜。
        格式要求：
        1. 第一行是榜单标题
        2. 第二行是榜单描述
        3. 接下来是每个排名项目，格式为：数字. 项目名称 - 项目描述
        4. 描述要简洁有力，突出特点
        5. 确保内容客观公正，数据可靠`
      },
      {
        role: "user",
        content: prompt
      }
    ];

    try {
      const completion = await this.createChatCompletion(messages);
      return completion.choices[0].message.content;
    } catch (error) {
      console.error('Failed to generate ranking content:', error);
      throw error;
    }
  }

  // 优化项目描述
  async enhanceDescription(title: string, description: string): Promise<string> {
    const messages = [
      {
        role: "system",
        content: "你是一个专业的文案优化助手。请优化项目描述，使其更加生动有趣，同时保持准确性和专业性。"
      },
      {
        role: "user",
        content: `请优化以下项目的描述：\n标题：${title}\n描述：${description}`
      }
    ];

    try {
      const completion = await this.createChatCompletion(messages);
      return completion.choices[0].message.content;
    } catch (error) {
      console.error('Failed to enhance description:', error);
      throw error;
    }
  }

  // 生成图片搜索关键词
  async generateImageKeywords(title: string, description: string): Promise<string[]> {
    const messages = [
      {
        role: "system",
        content: "你是一个专业的图片搜索关键词生成助手。请为给定的主题生成3-5个搜索关键词，以帮助找到最相关的图片。"
      },
      {
        role: "user",
        content: `请为以下内容生成图片搜索关键词：\n标题：${title}\n描述：${description}`
      }
    ];

    try {
      const completion = await this.createChatCompletion(messages);
      const keywords = completion.choices[0].message.content
        .split('\n')
        .filter(k => k.trim());
      return keywords;
    } catch (error) {
      console.error('Failed to generate image keywords:', error);
      throw error;
    }
  }
}

export default OpenRouterClient; 