import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/pages/Examples.css';

interface ExampleItem {
  title: string;
  description: string;
  image: string;
  tags: string[];
}

const Examples: React.FC = () => {
  const { t } = useTranslation();

  const getExamples = (): ExampleItem[] => {
    const examples = t('pages.examples.items', { returnObjects: true });
    return Array.isArray(examples) ? examples : [];
  };

  return (
    <div className="examples-page">
      <div className="page-header">
        <h1>{t('pages.examples.title')}</h1>
        <p>{t('pages.examples.description')}</p>
      </div>

      <div className="examples-grid">
        {getExamples().map((example: ExampleItem, index: number) => (
          <div key={index} className="example-card">
            <img src={example.image} alt={example.title} />
            <div className="example-content">
              <h3>{example.title}</h3>
              <p>{example.description}</p>
              <div className="example-tags">
                {example.tags.map((tag: string, tagIndex: number) => (
                  <span key={tagIndex} className="tag">{tag}</span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Examples; 