import { ImageGenerationConfig, ImageGenerationResult } from '../types/ImageGeneration';

class ImageGenerator {
  private apiToken: string;
  private defaultConfig: ImageGenerationConfig = {
    width: 768,
    height: 768,
    model: "stability-ai/stable-diffusion-3",
    quality: "standard",
    style: "photographic",
    negativePrompt: "low quality, blurry, bad anatomy, ugly, text, watermark"
  };

  constructor() {
    this.apiToken = process.env.REACT_APP_REPLICATE_API_TOKEN || '';
  }

  async generateImage(
    prompt: string, 
    config: Partial<ImageGenerationConfig> = {}
  ): Promise<ImageGenerationResult> {
    const finalConfig = { ...this.defaultConfig, ...config };

    try {
      const response = await fetch("https://api.replicate.com/v1/predictions", {
        method: "POST",
        headers: {
          "Authorization": `Token ${this.apiToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          version: finalConfig.model,
          input: {
            prompt: `${prompt}, ${finalConfig.style}, high quality, detailed`,
            negative_prompt: finalConfig.negativePrompt,
            width: finalConfig.width,
            height: finalConfig.height,
          }
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const prediction = await response.json();
      const result = await this.pollImageGeneration(prediction.id);

      return {
        url: result.output[0],
        status: 'success'
      };
    } catch (error) {
      console.error('Image generation failed:', error);
      return {
        url: `https://via.placeholder.com/${finalConfig.width}x${finalConfig.height}?text=${encodeURIComponent(prompt)}`,
        status: 'failed',
        error: error instanceof Error ? error.message : 'Unknown error'
      };
    }
  }

  private async pollImageGeneration(predictionId: string, maxAttempts = 30): Promise<any> {
    const interval = 1000;
    let attempts = 0;

    while (attempts < maxAttempts) {
      const response = await fetch(
        `https://api.replicate.com/v1/predictions/${predictionId}`,
        {
          headers: {
            "Authorization": `Token ${this.apiToken}`,
          },
        }
      );

      const prediction = await response.json();
      
      switch (prediction.status) {
        case "succeeded":
          return prediction;
        case "failed":
          throw new Error(prediction.error || "Image generation failed");
        case "canceled":
          throw new Error("Image generation was canceled");
        default:
          await new Promise(resolve => setTimeout(resolve, interval));
          attempts++;
      }
    }

    throw new Error("Image generation timeout");
  }
}

export default ImageGenerator; 