import React from 'react';
import { useTranslation } from 'react-i18next';

const Docs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="docs-page">
      <div className="page-header">
        <h1>{t('pages.docs.title')}</h1>
        <p>{t('pages.docs.description')}</p>
      </div>
      {/* 文档内容 */}
    </div>
  );
};

export default Docs; 