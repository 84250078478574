interface GroqResponse {
  choices: Array<{
    message: {
      content: string;
    };
  }>;
}

class GroqClient {
  private apiKey: string;
  private baseURL: string;

  constructor() {
    this.apiKey = process.env.REACT_APP_GROQ_API_KEY || '';
    this.baseURL = process.env.REACT_APP_API_BASE_URL || 'https://api.groq.com/openai/v1';
  }

  async createChatCompletion(messages: Array<{ role: string; content: string }>) {
    try {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${encodeURIComponent(this.apiKey)}`);
      headers.append('Content-Type', 'application/json');

      const response = await fetch(`${this.baseURL}/chat/completions`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          model: 'mixtral-8x7b-32768',
          messages,
          temperature: 0.7,
          max_tokens: 2000,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data: GroqResponse = await response.json();
      return data;
    } catch (error) {
      console.error('Groq API error:', error);
      throw error;
    }
  }
}

export default GroqClient; 