import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../components/LoadingSpinner';
import '../styles/pages/RankingList.css';

// 定义数据库中的排行榜类型
interface DBRanking {
  id: string;
  title: string;
  description: string;
  share_id: string;
  created_at: string;
  views: number;
  likes: number;
}

interface RankingResponse {
  rankings: {
    results: DBRanking[];
    success: boolean;
    meta: {
      served_by: string;
      duration: number;
      changes: number;
      last_row_id: number;
      changed_db: boolean;
      size_after: number;
      rows_read: number;
      rows_written: number;
    };
  };
  total: number;
}

const RankingListPage: React.FC = () => {
  const { t } = useTranslation();
  // 修改状态类型为 DBRanking
  const [rankings, setRankings] = useState<DBRanking[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const limit = 10;

  const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:4000/api';

  const fetchRankings = async (pageNum: number = 1) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${apiUrl}/rankings?page=${pageNum}&limit=${limit}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: RankingResponse = await response.json();
      
      const rankingsList = data.rankings?.results || [];
      const total = data.total || 0;
      
      if (pageNum === 1) {
        setRankings(rankingsList);
      } else {
        setRankings(prev => [...prev, ...rankingsList]);
      }
      
      setTotal(total);
      setHasMore(rankingsList.length === limit);
    } catch (error) {
      console.error('Failed to fetch rankings:', error);
      setError(t('rankingList.error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRankings();
  }, []);

  const loadMore = () => {
    if (!loading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchRankings(nextPage);
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  if (error) {
    return (
      <div className="ranking-list error">
        <p>{error}</p>
        <button onClick={() => fetchRankings(1)}>
          {t('common.retry')}
        </button>
      </div>
    );
  }

  return (
    <div className="ranking-list">
      <div className="page-header">
        <h1>{t('rankingList.title')}</h1>
        <p>{t('rankingList.description')}</p>
      </div>

      {rankings.length === 0 && !loading ? (
        <div className="no-rankings">
          <p>{t('rankingList.noRankings')}</p>
        </div>
      ) : (
        <div className="rankings-grid">
          {Array.isArray(rankings) && rankings.map((ranking) => (
            <Link 
              key={ranking.id} 
              to={`/share/${ranking.title}-${ranking.share_id}`}
              className="ranking-card"
            >
              <h3>{ranking.title}</h3>
              <p>{ranking.description}</p>
              <div className="ranking-meta">
                <span className="date">
                  {t('rankingList.createdAt', { 
                    date: formatDate(ranking.created_at)
                  })}
                </span>
                <div className="stats">
                  <span className="views">
                    👁️ {ranking.views || 0} {t('rankingList.views')}
                  </span>
                  <span className="likes">
                    ❤️ {ranking.likes || 0} {t('rankingList.likes')}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}

      {loading && (
        <div className="loading">
          <LoadingSpinner />
          <p>{t('rankingList.loading')}</p>
        </div>
      )}

      {hasMore && !loading && (
        <button 
          className="load-more-button"
          onClick={loadMore}
        >
          {t('rankingList.loadMore')}
        </button>
      )}

      {!hasMore && rankings.length > 0 && (
        <p className="no-more">
          {t('rankingList.noMore')}
        </p>
      )}
    </div>
  );
};

export default RankingListPage; 