import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-page">
      <div className="page-header">
        <h1>{t('pages.contact.title')}</h1>
        <p>{t('pages.contact.description')}</p>
      </div>
      {/* 联系表单 */}
    </div>
  );
};

export default Contact; 