import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/pages/Features.css';

interface FeatureItem {
  icon: string;
  title: string;
  description: string;
}

const Features: React.FC = () => {
  const { t } = useTranslation();

  // 添加类型检查和转换函数
  const getFeatures = (): FeatureItem[] => {
    const features = t('pages.features.items', { returnObjects: true });
    return Array.isArray(features) ? features : [];
  };

  return (
    <div className="features-page">
      <div className="page-header">
        <h1>{t('pages.features.title')}</h1>
        <p>{t('pages.features.description')}</p>
      </div>

      <div className="features-grid">
        {getFeatures().map((feature: FeatureItem, index: number) => (
          <div key={index} className="feature-card">
            <div className="feature-icon">{feature.icon}</div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features; 