import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import '../styles/Navbar.css';

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = (path: string) => location.pathname === path;

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="navbar-brand">
          <Link to="/" className="logo">
            <span className="logo-icon">📊</span>
            <span className="logo-text">RankMaker.AI</span>
          </Link>
        </div>
        
        <div className="navbar-menu">
          <Link 
            to="/generator" 
            className={`menu-item ${isActive('/generator') ? 'active' : ''}`}
          >
            {t('navbar.generator') as string}
          </Link>
          <Link 
            to="/rankings" 
            className={`menu-item ${isActive('/rankings') ? 'active' : ''}`}
          >
            {t('navbar.rankings')}
          </Link>
          <Link 
            to="/features" 
            className={`menu-item ${isActive('/features') ? 'active' : ''}`}
          >
            {t('navbar.features') as string}
          </Link>
          <Link 
            to="/templates" 
            className={`menu-item ${isActive('/templates') ? 'active' : ''}`}
          >
            {t('navbar.templates') as string}
          </Link>
          <Link 
            to="/examples" 
            className={`menu-item ${isActive('/examples') ? 'active' : ''}`}
          >
            {t('navbar.examples') as string}
          </Link>
          <Link 
            to="/pricing" 
            className={`menu-item ${isActive('/pricing') ? 'active' : ''}`}
          >
            {t('navbar.pricing') as string}
          </Link>
        </div>

        <div className="navbar-right">
          <LanguageSelector />
          <Link 
            to="/generator" 
            className="try-button"
          >
            {t('navbar.tryNow') as string}
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 