import React from 'react';
import { useTranslation } from 'react-i18next';
import TemplateSelector from '../components/TemplateSelector';

const Templates: React.FC = () => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = React.useState('modern');

  return (
    <div className="templates-page">
      <div className="page-header">
        <h1>{t('pages.templates.title')}</h1>
        <p>{t('pages.templates.description')}</p>
      </div>
      <TemplateSelector 
        selectedTemplate={selectedTemplate}
        onSelect={setSelectedTemplate}
      />
    </div>
  );
};

export default Templates; 