interface BingImageSearchResult {
  value: Array<{
    contentUrl: string;
    thumbnailUrl: string;
    name: string;
  }>;
}

interface SerperImageSearchResult {
  images: Array<{
    imageUrl: string;
    title: string;
  }>;
}

class ImageSearchService {
  private bingApiKey: string;
  private serperApiKey: string;
  private bingEndpoint: string;
  private serperEndpoint: string;

  constructor() {
    this.bingApiKey = process.env.REACT_APP_BING_SEARCH_API_KEY || '';
    this.serperApiKey = process.env.REACT_APP_SERPER_API_KEY || '';
    this.bingEndpoint = 'https://api.bing.microsoft.com/v7.0/images/search';
    this.serperEndpoint = 'https://google.serper.dev/images';
  }

  async searchImageWithSerper(query: string): Promise<string> {
    try {
      const response = await fetch(this.serperEndpoint, {
        method: 'POST',
        headers: {
          'X-API-KEY': this.serperApiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          q: query,
          gl: 'cn', // 地区设置为中国
          hl: 'zh-cn' // 语言设置为中文
        })
      });

      if (!response.ok) {
        throw new Error(`Serper HTTP error! status: ${response.status}`);
      }

      const data: SerperImageSearchResult = await response.json();
      
      if (data.images && data.images.length > 0) {
        return data.images[0].imageUrl;
      }
      throw new Error('No images found from Serper');
    } catch (error) {
      console.error('Serper search failed:', error);
      throw error;
    }
  }

  async searchImageWithBing(query: string): Promise<string> {
    try {
      const response = await fetch(
        `${this.bingEndpoint}?q=${encodeURIComponent(query)}&count=1&safeSearch=strict&mkt=zh-CN`,
        {
          method: 'GET',
          headers: {
            'Ocp-Apim-Subscription-Key': this.bingApiKey,
            'Accept': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error(`Bing HTTP error! status: ${response.status}`);
      }

      const data: BingImageSearchResult = await response.json();
      
      if (data.value && data.value.length > 0) {
        return data.value[0].contentUrl;
      }
      throw new Error('No images found from Bing');
    } catch (error) {
      console.error('Bing search failed:', error);
      throw error;
    }
  }

  // 添加图片有效性检查
  private async isImageValid(url: string): Promise<boolean> {
    try {
      const response = await fetch(url, { 
        method: 'HEAD',
        mode: 'no-cors'
      });
      return response.type === 'opaque' || response.ok;
    } catch {
      return false;
    }
  }

  // 带有重试和验证的搜索方法，优先使用 Serper，失败后回退到 Bing
  async searchImageWithFallback(query: string, retries = 3): Promise<string> {
    for (let i = 0; i < retries; i++) {
      try {
        // 首先尝试使用 Serper
        if (this.serperApiKey) {
          try {
            const serperUrl = await this.searchImageWithSerper(query);
            if (await this.isImageValid(serperUrl)) {
              return serperUrl;
            }
          } catch (error) {
            console.error('Serper search failed, falling back to Bing:', error);
          }
        }

        // Serper 失败后尝试使用 Bing
        if (this.bingApiKey) {
          const bingUrl = await this.searchImageWithBing(query);
          if (await this.isImageValid(bingUrl)) {
            return bingUrl;
          }
        }

        // 等待一段时间后重试
        await new Promise(resolve => setTimeout(resolve, 1000));
      } catch (error) {
        console.error(`Attempt ${i + 1} failed:`, error);
        if (i === retries - 1) {
          return `https://via.placeholder.com/400x400?text=${encodeURIComponent(query)}`;
        }
      }
    }
    return `https://via.placeholder.com/400x400?text=${encodeURIComponent(query)}`;
  }
}

export default ImageSearchService; 