import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/SocialShare.css';

interface Props {
  imageUrl: string;
  title: string;
  shareUrl?: string | null;
}

const SocialShare: React.FC<Props> = ({ imageUrl, title, shareUrl }) => {
  const { t } = useTranslation();

  const handleShare = (platform: string) => {
    let shareLink = '';
    const encodedTitle = encodeURIComponent(title);
    const encodedUrl = encodeURIComponent(shareUrl || window.location.href);
    const encodedImage = encodeURIComponent(imageUrl);

    switch (platform) {
      case 'weibo':
        shareLink = `http://service.weibo.com/share/share.php?url=${encodedUrl}&title=${encodedTitle}&pic=${encodedImage}`;
        break;
      case 'twitter':
        shareLink = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`;
        break;
      case 'facebook':
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      default:
        return;
    }

    window.open(shareLink, '_blank', 'width=600,height=500');
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl || window.location.href);
      alert(t('copySuccess'));
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="social-share">
      <h3>{t('shareTitle')}</h3>
      <div className="share-buttons">
        <button onClick={() => handleShare('weibo')} className="share-button weibo">
          {t('shareToWeibo')}
        </button>
        <button onClick={() => handleShare('twitter')} className="share-button twitter">
          {t('shareToTwitter')}
        </button>
        <button onClick={() => handleShare('facebook')} className="share-button facebook">
          {t('shareToFacebook')}
        </button>
        <button onClick={copyToClipboard} className="share-button copy">
          {t('copyLink')}
        </button>
      </div>
    </div>
  );
};

export default SocialShare; 