import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RankingGenerator from '../services/RankingGenerator';
import RankingImageGenerator from '../components/RankingImageGenerator';
import TemplateSelector from '../components/TemplateSelector';
import LoadingSpinner from '../components/LoadingSpinner';
import { RankingList } from '../types/RankingList';
import { templates } from '../config/templates';
import '../styles/pages/Generator.css';

interface GeneratorSettings {
  topN: number;
  imageCount: number;
  customImageCount: boolean;
}

const Generator: React.FC = () => {
  const { t } = useTranslation();
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [ranking, setRanking] = useState<RankingList | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState('modern');
  const [settings, setSettings] = useState<GeneratorSettings>({
    topN: 10,
    imageCount: 3,
    customImageCount: false
  });

  const generator = new RankingGenerator();

  const handleGenerate = async () => {
    if (!prompt.trim()) return;
    
    setLoading(true);
    try {
      const result = await generator.generateRanking(prompt, settings);
      result.template = selectedTemplate;
      setRanking(result);
    } catch (error) {
      console.error('Failed to generate ranking:', error);
      alert(t('generator.status.error'));
    } finally {
      setLoading(false);
    }
  };

  const handleSettingChange = (key: keyof GeneratorSettings, value: number | boolean) => {
    if (key === 'topN') {
      value = Math.max(1, Math.min(100, value as number));
    }
    if (key === 'imageCount' && typeof value === 'number') {
      value = Math.max(0, Math.min(100, value));
    }
    setSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };

  return (
    <div className="generator-page">
      <section className="input-section">
        <h1>{t('generator.title')}</h1>
        <p>{t('generator.description')}</p>

        <div className="input-container">
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder={t('generator.promptPlaceholder')}
            rows={4}
            disabled={loading}
          />

          <div className="settings-container">
            <div className="setting-item">
              <label>{t('generator.settings.topN.label')}</label>
              <input
                type="number"
                min="1"
                max="100"
                value={settings.topN}
                onChange={(e) => handleSettingChange('topN', parseInt(e.target.value) || 1)}
                disabled={loading}
              />
              <p className="setting-description">{t('generator.settings.topN.description')}</p>
            </div>

            <div className="setting-item">
              <label>{t('generator.settings.imageCount.label')}</label>
              {settings.customImageCount ? (
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={settings.imageCount}
                  onChange={(e) => handleSettingChange('imageCount', parseInt(e.target.value) || 0)}
                  disabled={loading}
                />
              ) : (
                <select
                  value={settings.imageCount}
                  onChange={(e) => handleSettingChange('imageCount', parseInt(e.target.value))}
                  disabled={loading}
                >
                  {[0, 1, 3, 5, 10].map(n => (
                    <option key={n} value={n}>
                      {t(`generator.settings.imageCount.options.${n === 0 ? 'none' : n === 1 ? 'one' : n === 10 ? 'all' : 'some'}`, { count: n })}
                    </option>
                  ))}
                </select>
              )}
              <button
                className="toggle-input-type"
                onClick={() => handleSettingChange('customImageCount', !settings.customImageCount)}
                disabled={loading}
              >
                {settings.customImageCount ? t('generator.settings.presetInput') : t('generator.settings.customInput')}
              </button>
            </div>
          </div>

          <button 
            className="generate-button"
            onClick={handleGenerate}
            disabled={loading || !prompt.trim()}
          >
            {loading ? t('generator.status.generating') : t('generator.buttons.generate')}
          </button>
        </div>
      </section>

      {loading && (
        <section className="loading-section">
          <LoadingSpinner />
          <p>{t('generator.tips.loading')}</p>
        </section>
      )}

      {ranking && !loading && (
        <>
          <section className="template-section">
            <h2>{t('templates.title')}</h2>
            <TemplateSelector
              selectedTemplate={selectedTemplate}
              onSelect={setSelectedTemplate}
            />
          </section>

          <section className="result-section">
            <RankingImageGenerator
              ranking={ranking}
              template={templates.find(t => t.id === selectedTemplate)!}
            />
          </section>
        </>
      )}
    </div>
  );
};

export default Generator;