import { RankingList } from '../types/RankingList';

class RankingStorageService {
  private baseUrl: string;
  private apiUrl: string;

  constructor() {
    this.baseUrl = window.location.origin;
    this.apiUrl = process.env.REACT_APP_API_BASE_URL || 'https://listify-api.your-subdomain.workers.dev';
  }

  private async request(method: string, endpoint: string, data?: any) {
    try {
      const response = await fetch(`${this.apiUrl}${endpoint}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: data ? JSON.stringify(data) : undefined
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return method === 'GET' ? await response.json() : true;
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  }

  private generateSlug(title: string): string {
    return title
      .toLowerCase()
      .replace(/[^\u4e00-\u9fa5a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '')
      .substring(0, 50);
  }

  private generateShareId(): string {
    const timestamp = Date.now().toString(36);
    const random = Math.random().toString(36).substring(2, 8);
    return `m3${random}`;
  }

  async saveRanking(rankingList: RankingList): Promise<string> {
    try {
      const share_id = this.generateShareId();
      const slug = this.generateSlug(rankingList.title);

      const kvKey = `ranking:${share_id}`;
      rankingList.shareId = share_id;
      rankingList.slug = slug;
      rankingList.exportedAt = new Date().toISOString();
      
      await this.request('PUT', `/kv/${kvKey}`, rankingList);

      await this.request('POST', '/rankings', {
        id: rankingList.id,
        title: rankingList.title,
        description: rankingList.description,
        share_id,
        slug
      });

      return `${this.baseUrl}/share/${slug}-${share_id}`;
    } catch (error) {
      console.error('Failed to save ranking:', error);
      throw error;
    }
  }

  async getRanking(param: string): Promise<RankingList | null> {
    try {
      const shareId = param.split('-').pop() || param;
      const kvKey = `ranking:${shareId}`;

      const data = await this.request('GET', `/kv/${kvKey}`);
      if (data) {
        await this.incrementViews(shareId);
        return data;
      }

      return null;
    } catch (error) {
      console.error('Failed to get ranking:', error);
      return null;
    }
  }

  async getAllRankings(page: number = 1, limit: number = 10): Promise<{
    rankings: RankingList[];
    total: number;
  }> {
    try {
      return await this.request('GET', `/rankings?page=${page}&limit=${limit}`);
    } catch (error) {
      console.error('Failed to get all rankings:', error);
      return { rankings: [], total: 0 };
    }
  }

  async getPopularRankings(limit: number = 10): Promise<RankingList[]> {
    try {
      return await this.request('GET', `/rankings/popular?limit=${limit}`);
    } catch (error) {
      console.error('Failed to get popular rankings:', error);
      return [];
    }
  }

  async incrementViews(shareId: string): Promise<void> {
    try {
      await this.request('POST', `/rankings/${shareId}/views`);
    } catch (error) {
      console.error('Failed to increment views:', error);
    }
  }

  async incrementLikes(shareId: string): Promise<void> {
    try {
      await this.request('POST', `/rankings/${shareId}/likes`);
    } catch (error) {
      console.error('Failed to increment likes:', error);
    }
  }
}

export default RankingStorageService; 