import React from 'react';
import { useTranslation } from 'react-i18next';

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="about-page">
      <div className="page-header">
        <h1>{t('pages.about.title')}</h1>
        <p>{t('pages.about.description')}</p>
      </div>
      {/* 关于我们内容 */}
    </div>
  );
};

export default About; 