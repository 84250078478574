import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Footer.css';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>{t('footer.about.title')}</h3>
          <p>{t('footer.about.description')}</p>
        </div>
        
        <div className="footer-section">
          <h3>{t('footer.contact.title')}</h3>
          <ul>
            <li>Email: support@rankmaker.ai</li>
            <li>Twitter: @RankMakerAI</li>
          </ul>
        </div>
        
        <div className="footer-section">
          <h3>{t('footer.links.title')}</h3>
          <ul>
            <li><a href="/terms">{t('footer.links.terms')}</a></li>
            <li><a href="/privacy">{t('footer.links.privacy')}</a></li>
            <li><a href="/api">{t('footer.links.api')}</a></li>
          </ul>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>
          © {currentYear} RankMaker.AI - {t('footer.version')} v1.0.0
        </p>
        <p className="footer-slogan">
          {t('footer.slogan')}
        </p>
      </div>
    </footer>
  );
};

export default Footer; 