import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout';
import Home from '../pages/Home';
import Generator from '../pages/Generator';
import Features from '../pages/Features';
import Templates from '../pages/Templates';
import Examples from '../pages/Examples';
import Pricing from '../pages/Pricing';
import Docs from '../pages/Docs';
import Blog from '../pages/Blog';
import About from '../pages/About';
import Contact from '../pages/Contact';
import SharePage from '../pages/SharePage';
import RankingList from '../pages/RankingList';

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/generator" element={<Generator />} />
          <Route path="/features" element={<Features />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/examples" element={<Examples />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/share/:shareId" element={<SharePage />} />
          <Route path="/rankings" element={<RankingList />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default AppRoutes; 