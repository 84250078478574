import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/pages/Pricing.css';

interface PricingPlan {
  name: string;
  price: string;
  currency: string;
  period: string;
  popular?: boolean;
  features: string[];
}

const Pricing: React.FC = () => {
  const { t } = useTranslation();

  // 添加类型检查和转换函数
  const getPricingPlans = (): PricingPlan[] => {
    const plans = t('pages.pricing.plans', { returnObjects: true });
    return Array.isArray(plans) ? plans : [];
  };

  return (
    <div className="pricing-page">
      <div className="page-header">
        <h1>{t('pages.pricing.title')}</h1>
        <p>{t('pages.pricing.description')}</p>
      </div>

      <div className="pricing-grid">
        {getPricingPlans().map((plan: PricingPlan, index: number) => (
          <div key={index} className={`pricing-card ${plan.popular ? 'popular' : ''}`}>
            {plan.popular && <div className="popular-badge">{t('pages.pricing.popularBadge')}</div>}
            <h3>{plan.name}</h3>
            <div className="price">
              <span className="currency">{plan.currency}</span>
              <span className="amount">{plan.price}</span>
              <span className="period">/{plan.period}</span>
            </div>
            <ul className="features">
              {plan.features.map((feature: string, featureIndex: number) => (
                <li key={featureIndex}>{feature}</li>
              ))}
            </ul>
            <button className={`select-plan ${plan.popular ? 'primary' : 'secondary'}`}>
              {t('pages.pricing.selectPlan')}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing; 