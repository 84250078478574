import { Template } from '../types/Template';

export const templates: Template[] = [
  {
    id: 'modern',
    name: '现代简约',
    description: '清新优雅的设计风格',
    previewImage: '/templates/modern.png',
    style: {
      backgroundColor: '#ffffff',
      headerBgColor: '#f8f9fa',
      titleColor: '#2c3e50',
      descriptionColor: '#666666',
      itemBackgroundColor: '#ffffff',
      rankColor: '#1890ff',
      fontFamily: "'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif",
      imageSize: { width: 200, height: 200 },
      borderRadius: '12px',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      itemSpacing: '16px',
      rankBgColor: '#e6f7ff',
      rankSize: '24px',
      titleFontSize: '18px',
      descriptionFontSize: '14px',
      itemPadding: '20px',
      itemBorder: '1px solid #f0f0f0',
      imageBorderRadius: '8px',
      imageBoxShadow: '0 2px 8px rgba(0,0,0,0.1)'
    }
  },
  {
    id: 'gradient',
    name: '渐变炫彩',
    description: '充满活力的现代设计',
    previewImage: '/templates/gradient.png',
    style: {
      backgroundColor: '#ffffff',
      gradient: 'linear-gradient(135deg, #6366f1 0%, #a855f7 100%)',
      titleColor: '#ffffff',
      descriptionColor: 'rgba(255,255,255,0.9)',
      itemBackgroundColor: 'rgba(255,255,255,0.1)',
      rankColor: '#ffffff',
      fontFamily: "'Inter', sans-serif",
      imageSize: { width: 200, height: 200 },
      borderRadius: '16px',
      boxShadow: '0 8px 32px rgba(0,0,0,0.15)',
      itemSpacing: '20px',
      rankBgColor: 'rgba(255,255,255,0.2)',
      rankSize: '28px',
      titleFontSize: '20px',
      descriptionFontSize: '15px',
      itemPadding: '24px',
      itemBorder: '1px solid rgba(255,255,255,0.2)',
      imageBorderRadius: '12px',
      imageBoxShadow: '0 4px 16px rgba(0,0,0,0.2)'
    }
  },
  {
    id: 'tech',
    name: '科技感',
    description: '未来科技风格',
    previewImage: '/templates/tech.png',
    style: {
      backgroundColor: '#0a0a0a',
      titleColor: '#00ff88',
      descriptionColor: '#888888',
      itemBackgroundColor: '#111111',
      rankColor: '#00ff88',
      fontFamily: "'JetBrains Mono', monospace",
      imageSize: { width: 200, height: 200 },
      borderRadius: '4px',
      boxShadow: '0 0 20px rgba(0,255,136,0.1)',
      itemSpacing: '12px',
      rankBgColor: 'rgba(0,255,136,0.1)',
      rankSize: '32px',
      titleFontSize: '18px',
      descriptionFontSize: '14px',
      itemPadding: '20px',
      itemBorder: '1px solid #222222',
      imageBorderRadius: '2px',
      imageBoxShadow: '0 0 16px rgba(0,255,136,0.2)'
    }
  },
  {
    id: 'minimal',
    name: '极简风格',
    description: '简约至上的设计',
    previewImage: '/templates/minimal.png',
    style: {
      backgroundColor: '#fafafa',
      titleColor: '#000000',
      descriptionColor: '#666666',
      itemBackgroundColor: '#ffffff',
      rankColor: '#000000',
      fontFamily: "'Helvetica Neue', sans-serif",
      imageSize: { width: 180, height: 180 },
      borderRadius: '0px',
      boxShadow: 'none',
      itemSpacing: '24px',
      rankSize: '20px',
      titleFontSize: '16px',
      descriptionFontSize: '14px',
      itemPadding: '16px',
      itemBorder: '1px solid #eeeeee',
      imageBorderRadius: '0px',
      imageBoxShadow: 'none'
    }
  },
  {
    id: 'elegant',
    name: '优雅格调',
    description: '高端大气设计',
    previewImage: '/templates/elegant.png',
    style: {
      backgroundColor: '#f8f5f0',
      titleColor: '#2c1810',
      descriptionColor: '#5c4030',
      itemBackgroundColor: '#ffffff',
      rankColor: '#8b4513',
      fontFamily: "'Playfair Display', serif",
      imageSize: { width: 220, height: 220 },
      borderRadius: '8px',
      boxShadow: '0 4px 20px rgba(139,69,19,0.1)',
      itemSpacing: '24px',
      rankBgColor: '#fff5e6',
      rankSize: '26px',
      titleFontSize: '22px',
      descriptionFontSize: '16px',
      itemPadding: '28px',
      itemBorder: '1px solid #e8d5c4',
      imageBorderRadius: '4px',
      imageBoxShadow: '0 2px 12px rgba(139,69,19,0.1)'
    }
  }
]; 