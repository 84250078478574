import { RankingList, RankingItem, RankingImage } from '../types/RankingList';
import ImageGenerator from './ImageGenerator';
import ImageSearchService from './ImageSearchService';
import GroqClient from './GroqClient';
import OpenRouterClient from './OpenRouterClient';

interface GenerateOptions {
  topN: number;
  imageCount: number;
  template?: string;
}

class RankingGenerator {
  private groq: GroqClient;
  private openRouter: OpenRouterClient;
  private imageGenerator: ImageGenerator;
  private imageSearchService: ImageSearchService;

  constructor() {
    this.groq = new GroqClient();
    this.openRouter = new OpenRouterClient();
    this.imageGenerator = new ImageGenerator();
    this.imageSearchService = new ImageSearchService();
  }

  private cleanMarkdown(text: string): string {
    return text
      // 保留数字序号和标题格式
      .replace(/^(\d+)\.\s+/gm, '$1. ') // 保留数字序号
      // 移除代码块
      .replace(/```[\s\S]*?```/g, '')
      // 移除行内代码
      .replace(/`([^`]+)`/g, '$1')
      // 移除标题标记
      .replace(/^#{1,6}\s/gm, '')
      // 移除粗体
      .replace(/\*\*([^*]+)\*\*/g, '$1')
      // 移除斜体
      .replace(/\*([^*]+)\*/g, '$1')
      .replace(/_([^_]+)_/g, '$1')
      // 移除链接，保留链接文本
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1')
      // 移除列表标记，但保留数字序号
      .replace(/^[\*\-]\s+/gm, '')
      // 移除引用
      .replace(/^>\s+/gm, '')
      // 移除水平线
      .replace(/^[-*_]{3,}/gm, '')
      // 移除多余的空行，但保留段落格式
      .replace(/\n{3,}/g, '\n\n')
      .trim();
  }

  private async parseGPTResponse(text: string): Promise<RankingList> {
    try {
      // 首先清理不需要的 markdown 标记
      const cleanedText = this.cleanMarkdown(text);
      // 按行分割并过滤空行
      const lines = cleanedText.split('\n').filter(line => line.trim());
      
      if (lines.length < 3) {
        throw new Error('Invalid response format');
      }

      // 提取标题和描述
      const title = lines[0].trim();
      const description = lines[1].trim();
      
      // 解析排名项目
      const items: RankingItem[] = [];
      const itemRegex = /^(\d+)\.\s+(.+?)\s*(?:-\s*(.+))?$/;

      for (let i = 2; i < lines.length; i++) {
        const match = lines[i].match(itemRegex);
        if (match) {
          const [, rankStr, itemTitle, itemDescription = ''] = match;
          const rank = parseInt(rankStr);
          
          if (!isNaN(rank)) {
            items.push({
              rank,
              title: itemTitle.trim(),
              description: itemDescription.trim(),
              images: []
            });
          }
        }
      }

      // 生成唯一ID
      const id = `ranking_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

      return {
        id,
        title,
        description,
        items,
        template: 'modern', // 默认模板
        createdAt: new Date(),
        updatedAt: new Date()
      };
    } catch (error) {
      console.error('Failed to parse GPT response:', error);
      throw error;
    }
  }

  private async generateItemImages(item: RankingItem, count: number): Promise<RankingImage[]> {
    if (count <= 0) return [];

    const images: RankingImage[] = [];
    const queries = this.generateSearchQueries(item, count);

    for (const query of queries) {
      try {
        const imageUrl = await this.imageSearchService.searchImageWithFallback(query);
        images.push({
          url: imageUrl,
          status: 'success'
        });
      } catch (error) {
        console.error(`Failed to generate image for query "${query}":`, error);
        images.push({
          url: `https://via.placeholder.com/400x400?text=${encodeURIComponent(item.title)}`,
          status: 'failed'
        });
      }

      if (images.length >= count) break;
    }

    return images;
  }

  private generateSearchQueries(item: RankingItem, count: number): string[] {
    const queries: string[] = [];
    const { title, description } = item;

    // 生成不同角度的搜索关键词
    const aspects = [
      '整体展示',
      '细节特写',
      '使用场景',
      '产品logo',
      '实际应用',
      '特色功能',
      '用户体验',
      '比较对比',
      '历史发展',
      '未来展望'
    ];

    for (let i = 0; i < count; i++) {
      const aspect = aspects[i % aspects.length];
      queries.push(`${title} ${description} ${aspect}`);
    }

    return queries;
  }

  async generateRanking(prompt: string, options: GenerateOptions): Promise<RankingList> {
    try {
      const completion = await this.openRouter.createChatCompletion([{
        role: "user",
        content: `生成一个关于 ${prompt} 的排行榜，包含标题、描述和前${options.topN}个项目。格式如下:
        标题
        描述
        1. 项目名称 - 描述
        2. 项目名称 - 描述
        ...`
      }]);

      const rankingData = await this.parseGPTResponse(completion.choices[0].message.content || '');
      
      // 使用传入的模板，如果没有传入则使用 'modern' 作为默认值
      rankingData.template = options.template || 'modern';

      // 为每个项目生成指定数量的配图
      for (const item of rankingData.items) {
        item.images = await this.generateItemImages(item, options.imageCount);
        // 设置主图为第一张成功的图片
        const successImage = item.images.find(img => img.status === 'success');
        item.imageUrl = successImage?.url || item.images[0]?.url;
      }

      return rankingData;
    } catch (error) {
      console.error('Failed to generate ranking:', error);
      throw error;
    }
  }
}

export default RankingGenerator;