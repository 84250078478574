import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import AppRoutes from './routes';
import './styles/index.css';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <AppRoutes />
    </HelmetProvider>
  );
};

export default App; 